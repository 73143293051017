// material-ui
import { Box } from '@mui/material';

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AuthBackground = () => {
  return (
    <Box sx={{ position: 'absolute', filter: 'blur(18px)', zIndex: -1, bottom: 0 }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="900.000000pt"
        height="483.000000pt"
        viewBox="0 0 300.000000 146.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        {' '}
        <g transform="translate(0.000000,146.000000) scale(0.100000,-0.100000)" fill="#6882ad" stroke="none">
          {' '}
          <path d="M1619 1368 c-28 -11 -79 -57 -79 -72 -1 -33 -17 -38 -60 -16 -89 45 -236 34 -330 -25 -129 -81 -182 -259 -118 -395 14 -30 35 -65 48 -78 l22 -23 -36 -39 c-54 -59 -61 -151 -17 -211 20 -27 20 -27 -1 -45 -65 -60 -83 -183 -38 -263 14 -24 42 -58 63 -75 l39 -31 218 -3 c189 -3 223 -1 252 14 80 40 121 108 121 199 0 90 -39 156 -118 198 -35 19 -61 22 -227 27 -186 5 -188 5 -214 31 -36 36 -36 92 -1 127 19 19 29 23 39 15 22 -18 99 -35 158 -35 192 0 334 135 333 317 -1 74 -20 140 -53 182 -25 33 -25 63 0 91 19 21 72 37 100 30 10 -2 14 9 14 42 l1 45 -46 1 c-25 0 -56 -3 -70 -8z m71 -37 c0 -4 -20 -16 -44 -25 -32 -12 -49 -26 -60 -50 -21 -45 -20 -53 9 -104 74 -130 63 -257 -29 -360 -71 -78 -194 -113 -301 -86 -33 8 -74 16 -91 17 -37 3 -64 22 -64 46 0 9 -11 32 -24 52 -79 116 -67 266 31 365 63 63 123 88 218 88 53 1 98 -5 132 -17 59 -21 80 -15 103 28 8 15 26 33 40 40 28 16 80 20 80 6z m-580 -651 c0 -5 -4 -12 -10 -15 -18 -11 -11 -81 12 -110 36 -46 70 -54 244 -56 171 -2 206 -10 258 -57 35 -33 65 -98 61 -132 -1 -14 -3 -34 -4 -45 -2 -38 -54 -105 -97 -125 -36 -18 -62 -20 -235 -20 -177 0 -197 2 -227 20 -89 54 -115 133 -82 243 15 50 20 53 52 36 l20 -11 -21 -19 c-40 -37 -40 -124 0 -179 38 -51 79 -60 272 -60 201 0 226 8 265 81 43 80 11 184 -66 216 -29 12 -76 14 -237 12 -144 -3 -206 0 -216 8 -8 6 -23 36 -33 67 -17 49 -18 62 -6 115 11 54 14 59 31 50 11 -5 19 -14 19 -19z m440 -265 c79 -41 79 -179 0 -220 -42 -22 -368 -22 -410 0 -78 41 -80 177 -2 219 40 22 370 23 412 1z" />{' '}
          <path d="M1213 1214 c-84 -41 -132 -120 -133 -223 0 -108 42 -182 132 -227 107 -54 236 -38 313 39 124 124 94 336 -57 411 -72 36 -180 36 -255 0z m232 -22 c81 -41 127 -114 127 -201 1 -99 -40 -166 -127 -207 -69 -33 -145 -32 -213 2 -65 32 -98 71 -118 140 -36 124 35 246 164 284 44 13 125 4 167 -18z" />{' '}
          <path d="M1820 1370 c0 -3 73 -78 163 -168 l162 -162 64 64 64 64 -88 84 c-48 46 -101 90 -119 99 -21 12 -65 18 -138 21 -60 2 -108 1 -108 -2z m234 -50 c23 -11 70 -48 104 -83 l64 -64 -32 -37 c-18 -20 -37 -36 -42 -36 -12 0 -238 220 -238 232 0 16 104 8 144 -12z" />{' '}
          <path d="M2350 1135 l235 -235 63 63 62 62 -152 153 c-88 87 -170 161 -194 172 -32 16 -61 20 -145 20 l-105 0 236 -235z m25 168 c51 -34 285 -263 285 -279 0 -12 -65 -74 -78 -74 -16 0 -372 372 -366 382 12 19 115 0 159 -29z" />{' '}
          <path d="M908 1314 c-26 -8 -64 -30 -84 -49 -20 -19 -38 -35 -39 -35 -1 0 0 20 3 45 l5 45 -52 0 -51 0 0 -305 0 -305 50 0 50 0 0 180 c0 199 6 225 67 282 23 22 101 64 111 59 1 0 13 -1 27 -1 23 0 25 3 25 50 0 50 0 50 -32 49 -18 0 -54 -7 -80 -15z m70 -41 c-2 -11 -18 -20 -45 -26 -53 -10 -124 -72 -152 -132 -18 -39 -21 -63 -21 -192 0 -150 -7 -186 -28 -161 -7 9 -10 94 -9 263 2 215 4 250 17 253 9 1 20 -9 27 -28 6 -16 17 -30 25 -30 8 0 38 16 68 35 61 39 124 49 118 18z" />{' '}
          <path d="M130 1269 l0 -50 165 4 165 5 -20 -26 c-11 -15 -85 -116 -165 -225 -120 -164 -145 -204 -145 -233 l0 -34 240 0 240 0 0 50 0 50 -152 -1 c-84 -1 -164 -4 -178 -9 l-25 -7 25 -7 c14 -4 83 -8 155 -9 108 -2 130 -5 130 -17 0 -21 -376 -28 -394 -7 -10 13 27 74 126 206 10 12 50 66 90 118 80 107 94 140 67 160 -10 7 -27 13 -38 13 -130 1 -236 6 -241 12 -4 4 -4 11 0 17 8 14 381 15 389 2 7 -11 -33 -70 -172 -255 -105 -140 -142 -194 -142 -208 0 -26 30 6 107 110 47 64 120 162 161 217 49 65 78 113 82 137 l7 38 -238 0 -239 0 0 -51z" />{' '}
          <path d="M2050 905 l-235 -235 105 0 c78 0 115 5 142 18 21 9 109 88 198 176 l160 160 -57 58 c-32 32 -62 58 -68 58 -5 0 -116 -106 -245 -235z m289 160 c17 -20 31 -41 31 -47 0 -23 -273 -281 -317 -300 -44 -19 -129 -22 -140 -5 -6 10 368 387 384 387 6 0 25 -16 42 -35z" />{' '}
          <path d="M2270 835 l-165 -165 95 0 c139 0 156 7 269 113 l98 92 -60 63 c-34 34 -64 62 -67 62 -3 0 -79 -74 -170 -165z m215 73 l27 -32 -80 -77 c-54 -53 -92 -81 -118 -88 -43 -12 -124 -15 -124 -4 0 3 33 36 72 72 40 36 93 87 117 114 25 26 53 47 62 47 9 0 29 -14 44 -32z" />{' '}
          <path d="M1810 455 c0 -51 3 -65 15 -65 11 0 15 11 15 36 l0 36 18 -22 19 -23 16 24 17 23 0 -37 c0 -26 4 -37 15 -37 12 0 15 14 15 65 0 36 -3 65 -7 65 -5 0 -19 -17 -32 -37 l-24 -37 -23 30 c-13 16 -24 32 -24 37 0 4 -4 7 -10 7 -6 0 -10 -28 -10 -65z" />{' '}
          <path d="M2035 508 c-27 -15 -35 -28 -35 -59 0 -34 29 -59 67 -59 35 0 63 27 63 61 0 30 -9 43 -37 58 -26 14 -33 13 -58 -1z m66 -39 c14 -27 -6 -59 -37 -59 -17 0 -28 8 -36 26 -11 23 -10 29 10 45 26 20 48 16 63 -12z" />{' '}
          <path d="M2170 511 c0 -16 50 -121 57 -121 11 0 56 120 49 128 -10 9 -22 -9 -36 -53 -14 -43 -14 -42 -40 28 -9 25 -30 38 -30 18z" />{' '}
          <path d="M2347 513 c-4 -3 -7 -33 -7 -65 l0 -58 45 0 c25 0 45 5 45 10 0 6 -13 10 -30 10 -23 0 -30 5 -30 19 0 14 5 18 20 14 14 -4 20 0 20 11 0 11 -9 16 -26 16 -14 0 -23 4 -19 10 3 6 19 10 36 10 19 0 29 5 29 15 0 11 -11 15 -38 15 -21 0 -42 -3 -45 -7z" />{' '}
          <path d="M1967 289 c-50 -38 -24 -111 40 -111 29 0 63 33 63 62 0 23 -29 57 -56 64 -13 4 -30 -1 -47 -15z m73 -29 c8 -14 8 -26 0 -40 -19 -35 -80 -20 -80 20 0 40 61 55 80 20z" />{' '}
          <path d="M2326 268 l-18 -43 -15 38 c-9 20 -21 37 -28 37 -9 0 -6 -19 13 -65 14 -36 29 -62 33 -58 3 5 12 26 19 48 l13 40 23 -50 c14 -33 24 -45 28 -35 42 115 44 120 31 120 -7 0 -20 -19 -28 -42 l-14 -43 -13 40 c-18 59 -25 60 -44 13z" />{' '}
          <path d="M2492 250 c-18 -39 -25 -67 -19 -69 6 -2 13 1 15 8 2 6 16 11 32 11 16 0 30 -5 32 -11 2 -7 9 -10 15 -8 6 2 -1 29 -18 69 l-28 65 -29 -65z" />{' '}
          <path d="M1820 240 c0 -33 4 -60 10 -60 6 0 10 11 10 25 0 20 5 25 25 25 14 0 25 5 25 10 0 6 -11 10 -25 10 -16 0 -25 6 -25 15 0 10 10 15 30 15 17 0 30 5 30 10 0 6 -18 10 -40 10 l-40 0 0 -60z" />{' '}
          <path d="M2120 240 c0 -33 4 -60 10 -60 6 0 10 11 10 25 0 14 4 25 9 25 5 0 18 -11 29 -25 27 -34 47 -33 25 2 -13 19 -14 28 -5 31 15 5 16 34 0 50 -7 7 -27 12 -45 12 l-33 0 0 -60z m66 29 c7 -12 -12 -29 -33 -29 -7 0 -13 9 -13 20 0 22 34 28 46 9z" />{' '}
          <path d="M2630 241 c0 -34 4 -61 10 -61 6 0 10 11 10 25 0 32 15 32 36 0 9 -14 23 -25 32 -25 12 0 11 5 -6 23 -21 22 -21 24 -5 40 25 25 6 51 -41 55 l-36 3 0 -60z m58 22 c-5 -26 -38 -29 -38 -4 0 15 6 21 21 21 14 0 19 -5 17 -17z" />{' '}
          <path d="M2770 240 l0 -60 38 0 c45 0 72 24 72 63 0 37 -25 57 -71 57 l-39 0 0 -60z m84 18 c14 -23 -3 -52 -33 -56 -19 -3 -21 1 -21 38 0 37 2 41 21 38 12 -2 27 -11 33 -20z" />{' '}
        </g>{' '}
      </svg>
    </Box>
  );
};
export default AuthBackground;
